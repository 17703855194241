import React from 'react';
import './Footer.css';
import aboutImage from '../assets/fizer.png'; // Ensure the logo is in the correct path
import linkedinIcon from '../assets/linkedin.png'; // Use the correct path to your LinkedIn icon image
import whatsappIcon from '../assets/speech.gif'; // Use the correct path to your WhatsApp icon image

function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="footer-container">
        {/* Left Section */}
        <div className="footer-left">
          <img src={aboutImage} alt="Fizer International Logo" className="footer-logo" />
          <p className="footer-intro">
            Fizer International is a leader in providing high-quality woven sack machinery parts, delivering innovative and reliable solutions for industries worldwide.
          </p>
        </div>

        {/* Center Section */}
        <div className="footer-center">
          <p>&copy; 2024 Fizer International. All Rights Reserved.</p>
          <div className="footer-links">
            <a href="tel:+918849565995" className="footer-link">Call Us: +91 88495 65995</a>
            <a href="mailto:info@fizerinternational.com" className="footer-link">Email Us: info@fizerinternational.com</a>
            <a href="mailto:export@fizerinternational.com" className="footer-link">Email Us: export@fizerinternational.com</a>
            <a href="mailto:sales@fizerinternational.com" className="footer-link">Email Us: sales@fizerinternational.com</a>
            <a href="https://linkedin.com/in/fizer-international-tech-industries-b4a6b0305" target="_blank" rel="noopener noreferrer" className="footer-link">
              <img src={linkedinIcon} alt="LinkedIn" className="footer-icon" /> LinkedIn
            </a>
          </div>
          
          {/* WhatsApp Button */}
          <a href="https://wa.me/918849565995" target="_blank" rel="noopener noreferrer" className="whatsapp-link">
            <img src={whatsappIcon} alt="WhatsApp" className="whatsapp-icon" />
            <span>WhatsApp Us</span>
          </a>
        </div>
      </div>

      {/* New Services Section Below WhatsApp */}
      <div className="footer-services">
        <h4>Our Services</h4>
        <ul className="services-list">
          <li>Erection & Commissioning</li>
          <li>Technical Service Support</li>
          <li>Online Chat Support</li>
          <li>Maintenance & Repair</li>
          <li>Custom Solutions</li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
