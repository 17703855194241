import React from 'react';
import './Services.css';

// Import images from the 'src/assets' folder
import new1 from '../assets/new1.jpg';
import new2 from '../assets/new2.jpg';
import new3 from '../assets/new3.jpg';
import new4 from '../assets/new4.jpg';
import new5 from '../assets/new5.jpg';
import new6 from '../assets/new6.jpg';
import new7 from '../assets/new7.jpg';
import new8 from '../assets/new8.jpg';
import new9 from '../assets/new9.jpg';

const servicesData = [
  { imgSrc: new1, title: 'Machine Part 1' },
  { imgSrc: new2, title: 'Machine Part 2' },
  { imgSrc: new3, title: 'Machine Part 3' },
  { imgSrc: new4, title: 'Machine Part 4' },
  { imgSrc: new5, title: 'Machine Part 5' },
  { imgSrc: new6, title: 'Machine Part 6' },
  { imgSrc: new7, title: 'Machine Part 7' },
  { imgSrc: new8, title: 'Machine Part 8' },
  { imgSrc: new9, title: 'Machine Part 9' },
];

const Services = () => {
  return (
    <section id="services" className="services">
      <h2>Our Services</h2>
      <div className="service-list">
        {servicesData.map((service, index) => (
          <div key={index} className="service-item">
            <img src={service.imgSrc} alt={`Image of ${service.title}`} />
            <h3>{service.title}</h3>
          </div>
        ))}
      </div>

      {/* Technical Services Section */}
      <h2>Technical Services</h2>
      <div className="technical-services">
        <div className="tech-service-item">
          <h3>Web Development</h3>
          <p>We offer comprehensive web development services, including front-end and back-end development, ensuring robust and scalable web applications.</p>
        </div>
        <div className="tech-service-item">
          <h3>IoT Support</h3>
          <p>We provide technical support for IoT projects, including device management, data handling, and cloud integration for seamless IoT solutions.</p>
        </div>
        <div className="tech-service-item">
          <h3>Cloud Solutions</h3>
          <p>Our team specializes in building cloud-based applications with a focus on scalability, security, and integration with various platforms.</p>
        </div>
        <div className="tech-service-item">
          <h3>AI/ML Solutions</h3>
          <p>Our team specializes in building AI/ML solutions based on client requirements.</p>
        </div>
        <div className="tech-service-item">
          <h3>Mobile Development</h3>
          <p>We provide mobile app development services for iOS and Android, building user-friendly and responsive apps that deliver great user experiences.</p>
        </div>
        <div className="tech-service-item">
          <h3>Full Stack</h3>
          <p>We handle projects from multiple domains. For any requirements, contact us via email, WhatsApp, or any preferable source! We and our team have hands-on experience working on live and real-world projects.</p>
        </div>
      </div>

      {/* Contact Form Button */}
      <div className="contact-button">
        <a href="http://www.fizerinternational.com" className="contact-btn">Contact Us</a>
      </div>

    </section>
  );
}

export default Services;
