import React from 'react';
import './About.css';
import aboutImage from '../assets/fizer.png'; // Replace with the actual path to your image
import gifAnimation from '../assets/phone.gif'; // Replace with the actual path to your GIF

function About() {
  return (
    <section id="about" className="about">
      <div className="about-content">
        <p>
          Welcome to Fizer International, where we strive to deliver global solutions with innovation and excellence.
          Fizer International Tech Industries is a leader in woven sack machinery parts that are reliable and cost-effective. 
          Our team is committed to excellence and quality, ensuring our clients receive the best in advanced technology 
          solutions for their industrial needs. We have an extensive range of machinery that caters to businesses looking for 
          both durability and affordability.
        </p>
        <div className="download-buttons">
          <a href="/assets/machine-names.pdf" download className="download-btn">
            Machine Catalog
          </a>
          <a href="/assets/machine-mod.pdf" download className="download-btn">
            Spare Parts
          </a>
          <a href="/assets/mach_names.png" download className="download-btn">
            Machines Overview
          </a>
        </div>
        {/* Wrap the GIF with an anchor tag for a phone call */}
        <a href="tel:+918849565995">
          <img src={gifAnimation} alt="Click to Call" className="dynamic-gif" />
        </a>
      </div>
      <div className="about-image-container">
        <img src={aboutImage} alt="About Us Logo" className="about-logo" />
        <p className="about-text-animation">Fizer International</p>
        <p className="ab">EXCELLENCE ON WAY!</p>
      </div>
    </section>
  );
}

export default About;
