import React, { useState } from 'react';
import './Header.css';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`header ${menuOpen ? 'active' : ''}`}>
      <div className="logo-container">
        <h1 className="logo">Fizer International Tech Industries</h1>
      </div>

      {/* Hamburger Menu for Mobile */}
      <div className="menu-toggle" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>

      {/* Navigation Menu */}
      <nav className={`nav ${menuOpen ? 'show' : ''}`}>
        <a href="#about" className="nav-link">About</a>
        <a href="#services" className="nav-link">Services</a>
        <a href="#footer" className="nav-link">Contact</a>
      </nav>
    </header>
  );
}

export default Header;
